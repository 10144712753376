.grille_admin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100%;
}

.div_tableau_groupe {
  grid-column: 1;
}

.div_tableau_restaurant {
  grid-column: 2;
}

.div_tableau_utilisateur {
  grid-column: 1 / 3;
}

.div_icone_ajouter {
  margin-left: auto; 
  margin-right: 0;
}

.icone_ajouter {
  background: #B5B5B5;
}

.div_tableau_titre {
  display: flex;
  flex-direction: row;
}

.tableau_titre {
  text-align: left;
  margin-left: 1rem;
}

.input-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.rs-input {
  width: 100%;
  margin: 0;
}

.passwordContainer {
  position: relative;
}

.passwordInput {
  width: calc(100% - 30px);
  padding-right: 30px;
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.selectCell {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

@media only screen and (max-width: 1200px) {
  .grille_admin {
    grid-template-columns: 1fr;
  }

  .div_tableau_groupe,
  .div_tableau_restaurant,
  .div_tableau_utilisateur {
    grid-column: 1;
  }

  .div_tableau_titre {
    justify-content: space-between;
  }
}
