.font {
  color: white !important;
  font-weight: 900 !important;
  padding: 1rem;
}

.font:focus {
  background-color: #F40020;
  color: white !important;
  font-weight: 900 !important;
}

.connexion {
  text-align: right;
}

.navigation {
  color: white;
  border: none;
  background-color: unset;
  font-weight: bold;
  padding-right: .5rem;
  padding-left: .5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
.navigation {
  margin-top: 1rem;
  }
}

.logo_connexion {
  width: 7rem;
  text-align: center;
  margin: 0 auto;
}

.active {
  background-color: #F40020;
  color: white !important;
  font-weight: 900 !important;
}

h5 {
  margin: auto;
}

.menu {
  background-color: #B5B5B5 !important;
  margin: unset;
  padding: unset;
}

.bouton_mobile {
  margin-right: 1rem;
}
