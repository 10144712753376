.div_form_connexion  {
  display: grid;
  margin: auto 0;
}

@media only screen and (min-width: 375px) and (max-width: 400px) {
  .div_form_connexion {
    width: 95% !important;
    margin: auto !important;
  }
  .logo_connexion {
    width: 20rem !important;
  }
}

@media only screen and (min-width: 1000px) {
  .div_form_connexion {
    width: 40%;
    margin: 5% auto;
  }
}

@media only screen and (min-width: 1500px) {
  .div_form_connexion {
    width: 30%;
    margin: 5% auto;
  }
}


.div_form_connexion form {
  display: grid;
  margin: auto 0;
}

.div_form_connexion form input {
  border: none;
  padding: 0.5em;
}

.div_form_connexion form label {
  text-align: left;
  font-weight: bold;
  margin: auto 0;
}

.div_form_connexion h4 {
  color: #F40020;
}

.button_connexion {
  margin: 5% auto;
  border-radius: 0;
  font-weight: bold;
  color: #F40020;
  border-color: #F40020;
}

.error_message {
  color: #F40020;
  font-weight: bold;
}

.logo_connexion {
  width: 25rem;
  text-align: center;
  margin: 0 auto;
}

.div_Copyright{
  margin: auto 0;
}

.passwordContainer {
  position: relative;
  display: inline-block; /* ou 'block' selon votre mise en page */
}

.passwordInput {
  width: 100%;
  /* autres styles pour votre input */
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 10px; /* ajustez en fonction de la taille de votre champ de saisie */
  transform: translateY(-50%);
  cursor: pointer;
  /* autres styles pour votre icône */
}
