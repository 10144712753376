.grille {
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

.grille_filtre {
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

@media only screen and (min-width: 600px) {
  .grille {
    grid-template-columns: repeat(2, 1fr);
  }
  .grille_filtre {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .grille {
    grid-template-columns: repeat(4, 1fr);
  }
  .grille_filtre {
    grid-template-columns: repeat(10, 1fr);
  }
}

.rbc-header {
  border: solid;
}

/* Conteneur principal pour la vue mobile, limité à 90vh */
.mobileCalendar {
  display: flex;
  flex-direction: column;
  height: 90vh;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0;
  overflow-x: hidden;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.monthLabel {
  text-align: center;
  margin-bottom: 1rem;
}

.daysBox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.dayItem {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 8px;
  cursor: pointer;
}

.dayHasServices {
  background-color: #ffcccc;
}

.dayFocused {
  background-color: #cc0000;
  color: #fff;
}

.tableContainer {
  flex: 1;
  overflow-y: auto;
}

.servicesTable {
  width: 100%;
  border-collapse: collapse;
}

.servicesTable thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #ddd;
  padding: 8px;
}

.servicesTable th,
.servicesTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Ligne vide pour le spacer en bas */
.spacerRow td {
  border: none;
  background: transparent;
}

/* Définition de la transition pour les lignes du tableau */
.dayRow {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.div_calendrier {
  margin: 2%;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

/* Style d'événement inspiré du Big Calendar */
.serviceButton {
  background-color: #3174ad; /* Couleur par défaut des events Big Calendar */
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 2px 6px;
  margin: 3px 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  /* Pour un effet "bouton" plus prononcé, on peut ajouter : */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.2); */
}

/* Facultatif : changement de couleur au survol */
.serviceButton:hover {
  background-color: #285f8f;
  text-decoration: none; /* évite un éventuel soulignement */
}

.btnAujourdhui {
  background-color: #f40020 !important;
  border-color: #f40020 !important;
  color: #fff !important;        /* Pour que le texte soit bien lisible */
}

.btnAujourdhui:hover,
.btnAujourdhui:focus,
.btnAujourdhui:active {
  background-color: #d3001d !important; /* nuance légèrement plus sombre */
  border-color: #d3001d !important;
  color: #fff !important;
}