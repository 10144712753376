.grille {
  display: grid;
  margin: 0.5rem 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
}

.grille_filtre {
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

.switch {
  margin: 0.5rem 1.5rem;
  text-align: left;
  display: flex;
}

.div_date {
  display: inline-flex;
  margin: auto !important;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 5px;
}

.date {
  margin: auto !important;
}

.switch .bouton_dashboard{
  border-radius: unset;
}

.graphique {
  margin: 10px 0.5rem;
  height: 30vh;
}

.graphique h2 {
  text-align: left;
}

.dashboardCard {
  margin: 20px 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6c757d;
}

.titleLive {
  color: #f9f9f9;
}

.customCardBody {
  background-color: #f0f0f0;
  padding: 16px; /* Optionnel : ajuster les marges internes */
  border-radius: 8px; /* Optionnel : arrondir les bords */
}


.toggleButton {
  font-size: 0.9rem;
  padding: 5px 10px;
}

/* Style pour le bouton "Afficher" (rouge) */
.showButton {
  background-color: #dc3545; /* Rouge */
  color: white;
  border: none;
}

/* Style pour le bouton "Cacher" (gris) */
.hideButton {
  background-color: #b5b5b5; /* Gris */
  color: white;
  border: none;
}

.tableContainer {
  margin: 20px auto;
  width: 90%;
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.customTable th,
.customTable td {
  border: 1px solid #ddd;
  padding: 15px 8px;
  position: relative;
}

/* Style pour les titres */
.customTable th {
  background-color: #d3d3d3; /* Gris foncé */
  font-weight: bold;
  color: #000; /* Texte noir */
}

/* Style pour la ligne Total Service */
.customTable .totalServiceRow {
  background-color: #e0e0e0; /* Gris clair */
  font-weight: bold;
}

.splitCell {
  position: relative;
  padding: 0;
  height: 50px; /* Ajustez selon vos besoins */
  background: #f0f0f0;
}

.splitCell .topPart,
.splitCell .bottomPart {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splitCell .topPart {
  top: 0;
}

.splitCell .topPart::before {
  content: "";
  position: absolute;
  bottom: 0; /* Place la "bordure" en bas de la partie supérieure */
  left: 0;
  width: 100%;
  height: 1px; /* Épaisseur de la "bordure" */
  background: linear-gradient(to right, #f0f0f0, #d8d8d8, #f0f0f0); /* Dégradé */
}

.splitCell .bottomPart {
  bottom: 0;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.liveIndicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: red;
  animation: pulse 1.5s infinite;
}

.noServiceCard {
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
  border: 1px solid #f5c6cb;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.tableHeader h2 {
  margin: 0;
}

.tableHeader .chrono {
  font-size: 16px;
  color: #555;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .switch {
    display: grid
  }
  .grille {
    grid-template-columns: repeat(1, 1fr);
  }
  .grille_filtre {
    grid-template-columns: repeat(4, 1fr);
  }
  .graphique {
    margin: 20px 0.5rem;
    height: 35vh;
    width: 80vw;
  }
}

@media only screen and (max-width: 700px) {
  .switch {
    display: grid
  }
  .grille {
    grid-template-columns: repeat(1, 1fr);
  }
  .graphique {
  margin: 30px 0.5rem;
  height: 35vh;
  }
}

/* Fusion des colonnes C.A en cours et C.A réalisé sous 500px */
@media only screen and (max-width: 500px) {
  /* Cacher les colonnes individuelles */
  .customTable th:nth-child(4),
  .customTable td:nth-child(3),
  .customTable td:nth-child(4) {
    display: none;
  }

  /* Afficher la colonne fusionnée */
  .customTable th:nth-child(5),
  .customTable td:nth-child(5) {
    display: table-cell;
  }

  .splitCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .splitCell .topPart,
  .splitCell .bottomPart {
    width: 100%;
    text-align: center;
  }

  .splitCell .topPart::before {
    content: "";
    display: block;
    height: 1px;
    background-color: #ccc;
    margin: 5px 0;
  }
}
/* Afficher la colonne fusionnée sous 500px */
@media only screen and (max-width: 500px) {
  .customTable th:nth-child(4),
  .customTable td:nth-child(4) {
    display: none; /* Cacher la colonne "C.A en cours" */
  }

  .customTable th:nth-child(5),
  .customTable td:nth-child(5) {
    display: none; /* Cacher la colonne "C.A réalisé" */
  }

  .customTable td:nth-child(3) {
    display: table-cell; /* Afficher la colonne fusionnée */
  }

  .splitCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .splitCell div {
    padding: 5px;
  }

  .splitCell .topPart {
    border-bottom: 1px solid #ddd; /* Ligne pour séparer les deux parties */
  }

  .bottomBorder{
    border-top: 1px solid #ddd;
  }

  .tableContainer {
    margin: 20px auto;
    width: 100%;
  }

  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin: 20px auto;
    width: 90%;
  }

  .tableHeader .chrono {
    font-size: 12px;
    color: #555;
    font-weight: bold;
    position: relative; /* Permet d'utiliser "top" */
    top: 25px; /* Décale vers le bas */
  }

  .dashboardCard {
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .customCardBody {
    background-color: #f0f0f0;
    padding: 16px 0px; /* Optionnel : ajuster les marges internes */
    border-radius: 8px; /* Optionnel : arrondir les bords */
  }
}

.refreshIcon {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

@media only screen and (min-width: 1500px) {
  .grille {
    grid-template-columns: repeat(2, 1fr);
  }
  .grille_filtre {
    grid-template-columns: repeat(10, 1fr);
  }
}

.spinner {
  margin: auto;
}

