.cumulForm {
  max-width: 500px;            /* Limite la largeur du formulaire */
  margin: 40px auto;           /* Centre le formulaire verticalement et horizontalement */
  padding: 20px;
  text-align: center;
  /*background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.datePickersContainer {
  display: flex;
  justify-content: center;
  gap: 20px;       /* Espace horizontal entre les date pickers */
  margin-bottom: 20px;
  flex-wrap: wrap; /* Permet le retour à la ligne si l’écran est trop petit */
}

.datePickerGroup {
  display: flex;
  align-items: flex-end;
}

.label_date {
  margin-right: 5px;
  font-weight: 500;
}

.datePickerInput {
  width: 140px;
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.validateButton {
  margin-top: 10px;
  background-color: #f40020;
  border-color: #f40020;
  color: #fff; /* Pour s’assurer que le texte est bien lisible */
}

/* État : survol de la souris */
.validateButton:hover,
.validateButton:focus {
  background-color: #d8001c; /* Couleur légèrement plus sombre */
  border-color: #d8001c;
  color: #fff;
}

/* État : bouton enfoncé */
.validateButton:active {
  background-color: #b30015;
  border-color: #b30015;
  color: #fff;
}

