.grille {
    display: grid;
    margin: 1rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }

.titre {
  text-align: left;
  margin: 1rem auto auto 1rem;
}

@media only screen and (min-width: 600px) {
  .grille {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .grille {
    grid-template-columns: repeat(3, 1fr);
  }
}

