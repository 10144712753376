.rapport_wrapper {
  display: grid;
  grid-template-columns: 250px 1fr; /* Colonne gauche fixe, contenu à droite flexible */
  gap: 1rem; /* Ajoute de l'espace entre les colonnes */
  width: 100%;
}

.annotationContainer {
  padding: 10px;
  margin: 5px 10px;
  max-width: 300px;
}

.annotationItem {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.annotationLabelTitre {
  flex: 1;
  text-align: left;
}

.annotationLabel {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}

.annotationValue {
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .rapport_wrapper {
    display: block;
    grid-template-columns: 250px 1fr; /* Colonne gauche fixe, contenu à droite flexible */
    gap: 1rem; /* Ajoute de l'espace entre les colonnes */
    width: 100%;
  }
}

.div_rapport {
  margin-top: 1rem;
  grid-column: 2;
  padding: 0 20px; /* Ajout de padding pour éviter que le contenu touche les bords */
}

.div_rapport_list {
  grid-column: 1;
  width: auto;
  text-align: left; 
  max-width: 250px; /* Fixer la largeur de la colonne de gauche */
}

.rapport_list {
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

.grille_titre {
  display: grid;
  margin: 0 1rem 1rem 1rem;
  grid-template-columns: auto;
  grid-template-rows: auto;
  text-align: justify;
}

.grille_titre h2 {
  text-align: justify;
  margin: auto;
}

.bouton_imprimer {
  grid-column: 2;
  grid-row: 1;
  border-radius: unset;
  margin: 0px 10px;
}

.grille_tableau {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grille_tableau table {
  width: auto;
  overflow: auto;
}

.grille_tableau_vente {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grille_tableau_vente table {
  width: auto;
  overflow: auto;
}

.section_titre h3 {
  margin: 1rem;
  text-align: left;
}

.tableau {
  text-align: left;
  padding: 1rem;
}

.tableau table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  width: -moz-available;         
  width: -webkit-fill-available; 
  overflow-x: auto;
  background-color: #ffffff;
}

.tableau tbody {
  background-color: white;
}

.tableau table thead tr th {
  background-color: grey;
  color: white;
  padding: 0.5rem 0.5rem;
  font-weight: bold !important;
}

.tableau table tbody td {
  padding: 0.5rem;
  color: grey;
}

.tableau table thead th:nth-child(n+2) {
  text-align: right;
}

.tableau table tbody td:nth-child(n+2) {
  text-align: right;
}

.tableau caption {
  background-color: #262626;
  color: white;
  padding: 0.5rem 0.5rem;
  caption-side: top;
  text-align: center;  
}

.grille_filtre {
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

@media only screen and (max-width: 850px) {
  .div_rapport {
    grid-column: 1;
    grid-row: 2;
  }

  .div_rapport_list {
    grid-column: 1;
    grid-row: 1;
  }

  .tableau {
    padding: 0;
    margin: 0;
  }

  .rapport_list {
    display: block ruby;
    display: -webkit-inline-box;
    display: -webkit-box;
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .bande {
    grid-template-columns: 1fr;
  }

  .App {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .grille {
    grid-template-columns: repeat(2, 1fr);
  }

  .grille_titre {
    margin: unset;
  }

  .grille_titre Button {
    grid-column: 4;
    grid-row: 1;
  }

  .grille_tableau {
    grid-template-columns: repeat(1, 1fr);
  }

  .grille_tableau_vente {
    grid-template-columns: repeat(1, 1fr);
  }

  .grille_filtre {
    grid-template-columns: repeat(10, 1fr);
  }

  .tableau table {
    margin: 1rem 0;
    overflow: scroll;
    width: 100;
  }

  .tableau table thead tr th {
    background-color: grey;
    color: white;
    padding: 0.1rem 0.1rem;
    font-weight: unset !important;
  }
}

@media only screen and (max-width: 599px) {
  .bouton_imprimer {
    visibility: hidden;
  }
}

@media only screen and (max-width: 400px) {
  .tableau table tbody td {
    padding: 0.0rem;
  }
}

@media only screen and (min-width: 1650px) {
  .bande {
    grid-template-columns: 1fr 1fr;
  }

  .grille {
    grid-template-columns: repeat(4, 1fr);
  }

  .grille_titre Button {
    grid-column: 6;
    grid-row: 1;
  }

  .grille_tableau {
    grid-template-columns: repeat(4, 1fr);
  }

  .grille_filtre {
    grid-template-columns: repeat(10, 1fr);
  }
}

.bande {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.document {
  margin: 1rem;
  border: solid;
  height: max-content;
  flex-grow: 1;
  object-fit: cover;
  max-width: 300px;
}

.document_entete {
  border-top: solid;
}

.document_commande {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 6fr 2fr;
}

.document_totaux {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.numtabres {
  grid-column: 1;
  grid-row: 2;
}

.longueur {
  width: max-content;
  text-align: center;
}

.spinner {
  margin: auto;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  * {
    float: none !important;
  }

  .bande {
    float: none !important;
    display: block;
    column-count: 3;
    position: relative;
  }

  .grille_tableau {
    float: none !important;
    display: block;
    column-count: 2;
    position: relative;
  }

  .grille_tableau_vente {
    float: none !important;
    display: block;
    column-count: 1;
    position: relative;
  }

  .document {
    text-align: center;
    display: block;
    position: relative;
    width: fit-content;
    break-before: auto;
    page-break-before: auto;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .tableau {
    text-align: center;
    display: block;
    position: relative;
    width: fit-content;
    break-before: auto;
    page-break-before: auto;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .bouton_imprimer {
    display: none;
  }

  .bouton_etat {
    display: none;
  }

  .spinner {
    display: none;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

.rapport4_titre_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 1rem;
}

.group_title {
  text-align: left;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.totaux_par_groupes_de_gestion {
  margin: 2rem 0;
}

.rapport4_table_wrapper {
  display: grid;
  grid-template-columns: 1fr; /* Assurez-vous que les tableaux sont sur une seule colonne */
  grid-gap: 1rem;
  margin: 1rem 0;
  /* padding: 20px; */
}

.rapport4_table {
  text-align: left;
  padding: 1rem;
  /*border: 1px solid #cccccc;*/
  background-color: #f8f8f8;
  /*border-radius: 4px;*/
  font-weight: normal;
}

.rapport6_table tbody tr td{
  text-align: center;
  padding: 1rem;
  /*border: 1px solid #cccccc;*/
  background-color: #f8f8f8;
  /*border-radius: 4px;*/
  font-weight: normal;
}

.rapport4_table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.rapport4_table thead tr th {
  background-color: #333333;
  color: white;
  padding: 10px;
  text-align: left;
}

.rapport4_table tbody tr td {
  padding: 8px;
  color: #333333;
  border-top: 1px solid #e0e0e0;
}

.total_famille_row {
  border-top: 2px solid #000;
  background-color: #333; /* Darker background for Total famille row */
  color: #fff; /* White text to contrast against the dark background */
  font-weight: bold;
}

.total_famille_row td {
  color: #ffffff; /* White text for better visibility */
}

.rapport4_table caption {
  background-color: #262626;
  color: white;
  padding: 10px;
  caption-side: top;
  font-weight: bold;
  text-align: center;
}

.rapport6_table th, .rapport6_table td {
  text-align: center; /* Centre le texte dans les en-têtes et les cellules */
  padding: 8px; /* Ajoute un peu d'espace autour du texte pour une meilleure lisibilité */
}

.rapport6_table th {
  background-color: #000; /* Couleur de fond noir */
  color: #fff; /* Couleur de texte blanc */
  text-align: center;
  padding: 8px;
}

.rapport6_table td {
  text-align: center !important;
}


.rapport4_table thead th:nth-child(n+2),
.rapport4_table tbody td:nth-child(n+2) {
  text-align: right;
}

.rapport6_table thead th:nth-child(n+2),
.rapport6_table tbody td:nth-child(n+2) {
  text-align: center;
}

/* Styles pour le menu coulissant */
.slideMenu {
  position: fixed;
  left: -100%; /* Cacher le menu en dehors de l'écran */
  top: 70px;
  height: 100%;
  width: 250px;
  height: fit-content;
  background-color: #fff;
  transition: left 0.3s ease; /* Animation pour faire glisser le menu */
  z-index: 1000;
  overflow-y: auto;
  /*padding: 20px;*/
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.menuOpen {
  left: 0; /* Faire glisser le menu sur l'écran */
}

/* Bouton pour ouvrir/fermer le menu */
.menuButton {
  position: fixed; /* Passer à "relative" pour que le bouton s'aligne naturellement sous la navbar */
  top: 90px; /* Ajouter un espacement depuis le haut si nécessaire */
  left: 0; /* Ajuster l'espacement sur la gauche */
  z-index: 1001; /* S'assurer qu'il est bien au-dessus du reste du contenu */
  background-color: #32383e;
  color: #fff;
  border: none;
  border-radius: 0 20px 20px 0;
  padding: 10px;
  cursor: pointer;
  white-space: normal; /* Autoriser le texte à se casser en plusieurs lignes */
  width: 50px; /* Ajuste selon la largeur souhaitée */
  text-align: center; /* Centrer le texte */
}

.menuButtonOpen {
  transition: left 0.3s ease;
  left: 250px; /* Position du bouton lorsque le menu est ouvert */
}

/* Styles pour la démarcation et le lien d'impression */
.menuDividerContainer {
  background-color: #32383e; /* Fond sombre */
  padding: 0.5rem 0; /* Pour créer de l'espace autour de la ligne */
}

.menuDivider {
  margin: 0; /* Supprime le margin pour la ligne elle-même */
  border: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, #ffffff, transparent);
  width: 90%; /* Ajuste la largeur de la ligne pour qu'elle ne touche pas les bords */
  margin: 0 auto; /* Centrer la ligne */
}

.printLink {
  display: block;
  text-align: left;
  padding: 10px;
  background-color: #32383e;
  color: #fff;
  text-decoration: none;
  padding: .75rem 1.25rem;
  cursor: pointer;
}

.printLink:hover {
  background-color: #444;
}

.rapport2_table_header, .rapport7_table_header {
  background-color: #333;
}

.rapport2_table_cell, .rapport7_table_cell {
  text-align: center; /* Centre le texte dans chaque cellule */
  padding: 10px;
  color: white; /* Couleur de texte blanche pour contraste */
  border-left: 2px solid #333; /* Bordure gauche */
  border-right: 2px solid #333; /* Bordure droite */
}


@media only screen and (max-width: 655px){
  .rapport4_table {
    text-align: center;
    padding: 1rem;
    /*border: 1px solid #cccccc;*/
    background-color: #f8f8f8;
    border-radius: 4px;
  }

  .rapport4_table thead th:nth-child(n+2),
  .rapport4_table tbody td:nth-child(n+2) {
    text-align: center;
  }

  .rapport6_table thead th:nth-child(n+2),
  .rapport6_table tbody td:nth-child(n+2) {
    text-align: center;
  }

  .rapport2_table_header, .rapport7_table_header {
    background-color: #333;
  }
  
  .rapport2_table_cell, .rapport7_table_cell {
    text-align: center; /* Centre le texte dans chaque cellule */
    padding: 10px;
    color: white; /* Couleur de texte blanche pour contraste */
    border-left: 2px solid #333; /* Bordure gauche */
    border-right: 2px solid #333; /* Bordure droite */
  }
}
/* Responsive adjustments for small screens */

@media only screen and (max-width: 700px) {
  .rapport_wrapper {
    grid-template-columns: 1fr; /* Utiliser toute la largeur de l'écran */
    padding: 0;
  }

  .div_rapport {
    padding: 0px;
  }

  .rapport4_table_wrapper {
    padding: 0;
    width: 100vw; /* Prendre toute la largeur de l'écran */
  }

  .rapport4_table {
    width: 100vw;
    padding: 0;
    /*border: 1px solid #cccccc;*/
    border-radius: 0;
  }

  .rapport4_table thead tr th {
    padding: 10px 5px;
  }

  .rapport4_table tbody tr td {
    padding: 8px 5px;
  }

  .rapport2_table_header, .rapport7_table_header {
    background-color: #333;
  }
  
  .rapport2_table_cell, .rapport7_table_cell {
    text-align: center; /* Centre le texte dans chaque cellule */
    padding: 10px;
    color: white; /* Couleur de texte blanche pour contraste */
    border-left: 2px solid #333; /* Bordure gauche */
    border-right: 2px solid #333; /* Bordure droite */
  }
}

@media only screen and (max-width: 655px) {
  .rapport4_table thead tr th {
    background-color: #333333;
    color: white;
    padding: 10px 2px;
    text-align: left;
  }
  
  .rapport4_table tbody tr td {
    padding: 8px 2px;
    color: #333333;
    border-top: 1px solid #e0e0e0;
  }

  .rapport2_table_header, .rapport7_table_header {
    background-color: #333;
  }
  
  .rapport2_table_cell, .rapport7_table_cell {
    text-align: center; /* Centre le texte dans chaque cellule */
    padding: 10px;
    color: white; /* Couleur de texte blanche pour contraste */
    border-left: 2px solid #333; /* Bordure gauche */
    border-right: 2px solid #333; /* Bordure droite */
  }
}

@media only screen and (max-width: 599px) {
  .mobileHide {
    display: none;
  }

  .tableHeureCelltitre {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tableHeureCell {
    border-bottom: 1px solid #ccc; /* Appliquer le border uniquement ici */
  }
  

  .rapport_wrapper {
    grid-template-columns: 1fr; /* Utiliser toute la largeur de l'écran pour la liste des rapports */
  }

  .rapportAlertWrapper {
    position: relative; /* Utiliser position relative pour positionner la div */
    top: 325px; /* Ajustez la position en fonction de vos besoins */
  }

  .rapport_list {
    display: block;
    width: 100vw; /* Utiliser toute la largeur de l'écran */
    overflow-x: auto; /* Permettre le défilement horizontal si nécessaire */
    white-space: nowrap; /* Empêcher les éléments de se casser sur plusieurs lignes */
    height: 90px;
  }

  .div_rapport {
    margin-top: 0;
    grid-column: 1; /* Faire en sorte que le contenu prenne toute la largeur également */
  }
  .chevron {
    width: 30px;
  }
  .bouton_imprimer_rapport4 {
    display: none; /* Masquer le bouton d'impression sous 599px */
  }
  
  .rapport2_table_header, .rapport7_table_header {
    background-color: #333;
  }
  
  .rapport2_table_cell, .rapport7_table_cell {
    text-align: center; /* Centre le texte dans chaque cellule */
    padding: 10px;
    color: white; /* Couleur de texte blanche pour contraste */
    border-left: 2px solid #333; /* Bordure gauche */
    border-right: 2px solid #333; /* Bordure droite */
  }
  
  
}

@media print {
  .rapport4_table {
    page-break-inside: avoid;
  }
}

@media only screen and (max-width: 655px) {
  /* Centrer le texte des titres sous 655px */
  .rapport4_table thead tr th {
    text-align: center;
  }

  /* Centrer le texte du premier élément du tableau Totaux */
  .rapport4_table tbody tr td:first-child {
    text-align: center;
  }

  /* Aligner à droite le texte des autres éléments du tableau Totaux */
  .rapport4_table tbody tr td:not(:first-child) {
    text-align: center;
  }

  /* Centrer le premier et le second élément des tableaux Familles */
  .rapport4_table tbody tr td:nth-child(1),
  .rapport4_table tbody tr td:nth-child(2) {
    text-align: center;
  }

  /* Aligner à droite le texte des autres éléments des tableaux Familles */
  .rapport4_table tbody tr td:nth-child(n+3) {
    text-align: center;
  }
  .first_cell {
    background-color: #f0f0f0; /* Couleur légèrement différente pour les premières cellules */
    font-weight: bold;
  }

  .serveurHeader {
    background-color: #cecece; /* Couleur de fond sombre */
    color: #fff; /* Texte blanc pour contraste */
    font-weight: bold; /* Texte en gras */
    padding: 10px; /* Plus de padding pour un meilleur rendu */
  }
  
  .rapport6_data {
    padding: 8px; /* Assurez-vous que les autres cellules ont un padding uniforme */
    border-top: 1px solid #ccc; /* Ligne séparatrice subtile entre les éléments */
  }
  .serveur-section {
    margin-bottom: 20px;  /* Espacement entre les serveurs */
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Ligne de séparation visuelle */
  }
  .rapport4_table_individual {
    width: 100%; /* Assure que le tableau prend toute la largeur disponible */
    border-collapse: collapse; /* Assure que les bordures des cellules sont jointes */
    margin-bottom: 20px; /* Espace entre chaque tableau */
    border: 1px solid #ccc; /* Bordure légère autour du tableau */
  }
  
  .rapport4_table_individual_wrapper {
    /* background-color: #f0f0f0;*/
    margin-bottom: 30px; /* Espacement entre les tableaux */
  }
  
  .titreCell, .valeurCell {
    padding: 8px; /* Padding intérieur pour chaque cellule */
    border: 1px solid #ddd; /* Bordure légère pour chaque cellule */
    text-align: left; /* Alignement du texte à gauche pour les titres */
  }
  
  .titreCell {
    font-weight: bold; /* Met le texte en gras pour les titres */
    width: 50%; /* Assure que le titre prend la moitié de la largeur */
    background-color: #f0f0f0; /* Couleur de fond pour les cellules titre */
  }
  
  .valeurCell {
    text-align: right; /* Alignement du texte à droite pour les valeurs */
    width: 50%; /* Assure que la valeur prend la moitié de la largeur */
  }
  
}


.even_row {
  background-color: #f1eded; /* Lighter background color */
}

.odd_row {
  background-color: #ffffff; /* White background color */
}